require('sweetalert');
require('./../../../CubeCMS/Assets/js/bxslider.js');
var is_iPad = navigator.userAgent.match(/iPad/i) != null;
var is_iPhone = navigator.userAgent.match(/iPhone/i) != null;

require('perfect-scrollbar');

// function remove_unneccessary_assets(){
// 	if(is_iPhone != 0){
// 		if(is_iPad == true){
// 			$('.non_responsive_image, .mobile_image').remove();
// 		}else{
// 			$('.non_responsive_image, .responsive_image').remove();
// 		}
// 	}else{
		
// 	}
	
// }
//remove_unneccessary_assets();
(function(){
	
}());